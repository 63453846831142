import React from 'react';

const RepLg = (props) => (
  <svg viewBox="0 0 152 152" fill="none" {...props}>
    <g style={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="87.3706" cy="105.396" rx="42.7222" ry="41.11" fill="#C2EDE0" />
    </g>
    <path
      d="M60.4475 73.7493C78.0507 73.7493 92.3208 59.5585 92.3208 42.0534C92.3208 24.5482 78.0507 10.3574 60.4475 10.3574C42.8444 10.3574 28.5742 24.5482 28.5742 42.0534C28.5742 59.5585 42.8444 73.7493 60.4475 73.7493Z"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M28.5742 113.369C28.5742 95.8663 42.8471 81.6729 60.4475 81.6729C78.048 81.6729 92.3208 95.8663 92.3208 113.369"
      stroke="black"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <line x1="18" y1="37" x2="30" y2="37" stroke="black" strokeWidth="4" />
    <line x1="18" y1="52" x2="30" y2="52" stroke="black" strokeWidth="4" />
    <line x1="20" y1="51" x2="20" y2="39" stroke="black" strokeWidth="4" />
    <line y1="-2" x2="12" y2="-2" transform="matrix(-1 0 0 1 103 39)" stroke="black" strokeWidth="4" />
    <line y1="-2" x2="12" y2="-2" transform="matrix(-1 0 0 1 103 54)" stroke="black" strokeWidth="4" />
    <line y1="-2" x2="12" y2="-2" transform="matrix(0 -1 -1 0 99 51)" stroke="black" strokeWidth="4" />
    <path
      d="M68 58.5C68 58.6368 67.8604 59.2337 66.5746 59.9144C65.3784 60.5477 63.5876 61 61.5 61C59.4124 61 57.6216 60.5477 56.4254 59.9144C55.1396 59.2337 55 58.6368 55 58.5C55 58.3632 55.1396 57.7663 56.4254 57.0856C57.6216 56.4523 59.4124 56 61.5 56C63.5876 56 65.3784 56.4523 66.5746 57.0856C67.8604 57.7663 68 58.3632 68 58.5Z"
      stroke="black"
      strokeWidth="4"
    />
    <line x1="68" y1="59" x2="94" y2="59" stroke="black" strokeWidth="4" />
    <line x1="96.5" y1="54" x2="96.5" y2="61" stroke="black" strokeWidth="5" />
    <defs>
      <filter
        id="filter0_d_123_2"
        x="19"
        y="3"
        width="83"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_123_2" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_123_2" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default RepLg;

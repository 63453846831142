import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import RepLg from '../components/icons/RepLg';

const FaqPage = () => (
  <Layout>
    <Seo title="Subscribe Pro Support" />

    <Header graphic={undefined}>Subscribe Pro Support</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="prose prose-lg max-w-none prose-headings:font-normal prose-h2:text-2xl prose-h3:text-xl">
          <div className="float-right ">
            <RepLg className="w-[16rem] h-auto" />
          </div>
          <p>
            Our team is here to help you get the most out of the Subscribe Pro platform. Support is available by phone, email, and
            ticket. Please reach out and let us know how we can help.
          </p>

          <h2>Reach Us via Phone</h2>
          <p>
            Hours: 9am - 5pm
            <br />
            Eastern Time (M-F)
            <br />
            Toll Free Voice:&nbsp;
            <a href="tel:8889183569" className="font-bold text-lg">
              (888) 918-3569
            </a>
          </p>

          <h2>Reach Us via Email</h2>
          <p>
            Anytime at&nbsp;
            <a href="mailto:support@subscribepro.com" rel="nofollow">
              <span>support@subscribepro.com</span>
            </a>
          </p>

          <h2>Support Ticketing</h2>
          <p>
            Our support ticketing system is available 24/7 at:&nbsp;
            <br />
            <a title="Subscribe Pro Support Ticketing" href="http://support.subscribepro.com/">
              http://support.subscribepro.com/
            </a>
          </p>

          <h2>Product Documentation</h2>
          <p>
            Subscribe Pro offers extensive product documentation. You can find it at:&nbsp;
            <br />
            <a title="Subscribe Pro Documentation" href="https://docs.subscribepro.com/">
              https://docs.subscribepro.com/
            </a>
          </p>
        </div>
      </Container>
    </section>

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default FaqPage;
